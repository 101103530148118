<template>
  <div>
    <b-modal
      id="warehouse-entry-modal"
      hide-header
      hide-footer
      centered
      size="lg"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      @shown="onShow"
    >
      <div class="header">
        <div class="modal-title">Entrada de produtos</div>
        <span class="icon-box">
          <Close class="close-svg stroke" @click="onClickCloseModal()" />
        </span>
      </div>
      <div class="body d-flex flex-column">
        <validation-observer ref="warehouseEntryForm">
          <b-row>
            <b-col cols="4">
              <b-form-group id="field_date">
                <label for="date" class="title">
                  Data
                </label>
                <date-picker
                  class="full"
                  v-model="form.date"
                  format="DD/MM/YYYY"
                  placeholder="DD/MM/AAAA"
                  :clearable="false"
                  :lang="langDatePicker"
                  :disabled="hasPaidBill"
                ></date-picker>
                <div v-if="verified && !form.date" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-form-group>
            </b-col>
            <b-col cols="8">
              <b-form-group>
                <label for="provider" class="title">
                  Fornecedor
                  <span v-if="!configuration.provider_required" class="help optional">(opcional)</span>
                </label>
                <multiselect
                  class="search-mode with-border"
                  id="provider"
                  v-model="form.provider"
                  track-by="id"
                  label="name"
                  placeholder="Selecionar"
                  :options="providers"
                  :loading="isLoading"
                  :searchable="true"
                  :allow-empty="true"
                  :option-height="40"
                  :internal-search="false"
                  :showLabels="false"
                  @search-change="getProvidersDebounce"
                  :disabled="hasPaidBill"
                >
                  <template slot="caret">
                    <div class="search">
                      <Search />
                    </div>
                  </template>

                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.name }}
                  </template>

                  <template slot="noOptions">Digite para pesquisar um fornecedor</template>

                  <template v-if="!isLoading" slot="noResult" slot-scope="props">
                    <li @click="$bvModal.show('provider-modal')">
                      <div v-if="!isLoading" class="multiselect__option custom-item">
                        <Plus class="icon" />
                        Adicionar Fornecedor {{ props.search ? `"${props.search}"` : '' }}
                      </div>
                    </li>
                  </template>
                </multiselect>
                <div v-if="verified && configuration.provider_required && !form.provider"
                     class="custom-invalid-feedback">Campo obrigatório
                </div>
              </b-form-group>
            </b-col>
            <div class="col-6" v-if="createBillToPay">
							<b-form-group id="category_id">
								<label for="category_id">Categoria</label>
								<multiselect
									id="category_id"
									v-model="form.category"
									track-by="id"
									label="name"
									placeholder="Selecionar"
									:options="categories"
									:allow-empty="false"
									:option-height="40"
									:showLabels="false"
									:showNoResults="false"
									class="with-border"
                  :disabled="hasPaidBill"
								>
									<template slot="caret">
										<div class="chevron">
											<ChevronDown/>
										</div>
									</template>
									<template slot="singleLabel" slot-scope="{ option }">
										{{ option.name }}
									</template>

									<template slot="noOptions"> Nenhuma opção</template>

									<template slot="noResult"> Nenhum resultado</template>
								</multiselect>
								<div
									v-if="verified && !form.category"
									class="custom-invalid-feedback"
								>
									Campo obrigatório
								</div>
							</b-form-group>
						</div>
            <div class="col-6" v-if="createBillToPay">
							<b-form-group>
								<label for="cost-center">
									Centro de custo
									<span class="help">(opcional)</span>
								</label>
								<CheckboxSelect
									id="cost-center"
									optionLabel="label"
									trackBy="value"
									:value="selectedCostCenters"
									:options="costCenterOptions"
									v-on:onChange="changeSelectedCostCenters"
									:key="selectedCostCenters.length"
                  :disabled="hasPaidBill"
								/>
							</b-form-group>
						</div>
            <b-col cols="12">
              <b-card class="form-card">
                <div
                  class="d-flex justify-content-between align-items-center accordion-header"
                  role="tab"
                >
                  <div>
                    <p class="accordion-title">
                      Informações da nota fiscal
                      <span class="optional">(Todos campos são opcionais)</span>
                    </p>
                  </div>
                  <ChevronDown
                    class="chevron"
                    v-b-toggle.invoice-data-accordion
                  />
                </div>
                <b-collapse
                  ref="accordion"
                  id="invoice-data-accordion"
                  accordion="accordion-invoice-data"
                  role="tabpanel"
                  :visible="!!entry?.id"
                >
                  <div class="divider"></div>
                  <b-row class="accordion-form">
                    <b-col cols="3">
                      <b-form-group
                        id="field_invoice_number"
                      >
                        <label for="invoice_number" class="title">
                          Nota fiscal
                        </label>
                        <b-form-input
                          id="invoice_number"
                          trim
                          autocomplete="off"
                          placeholder="Descrever"
                          v-model="form.invoice_number"
                          :disabled="hasPaidBill"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="3">
                      <b-form-group
                        id="field_serial_number"
                      >
                        <label for="serial_number" class="title">
                          Série
                        </label>
                        <b-form-input
                          id="serial_number"
                          trim
                          autocomplete="off"
                          placeholder="Descrever"
                          v-model="form.serial_number"
                          :disabled="hasPaidBill"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        id="field_access_key"
                      >
                        <label for="access_key" class="title">
                          Chave de acesso
                        </label>
                        <b-form-input
                          id="access_key"
                          trim
                          autocomplete="off"
                          placeholder="Descrever"
                          v-model="form.access_key"
                          :disabled="hasPaidBill"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="3">
                      <b-form-group id="field_delivery_value">
                        <label for="delivery_value" class="title">
                          Valor do frete
                        </label>
                        <b-form-input
                          autocomplete="off"
                          class="form-control"
                          id="delivery_value"
                          v-money="money"
                          v-model.lazy="form.delivery_value"
                          :disabled="hasPaidBill"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="3">
                      <b-form-group id="field_insurance_value">
                        <label for="insurance_value" class="title">
                          Valor do seguro
                        </label>
                        <b-form-input
                          autocomplete="off"
                          class="form-control"
                          id="insurance_value"
                          v-money="money"
                          v-model.lazy="form.insurance_value"
                          :disabled="hasPaidBill"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="3">
                      <b-form-group id="field_other_expenses_value">
                        <label id="other_expenses_value_label" for="other_expenses_value" class="title">
                          Outras despesas
                        </label>
                        <b-tooltip target="other_expenses_value_label" placement="top">Outras despesas acessórias
                        </b-tooltip>
                        <b-form-input
                          autocomplete="off"
                          class="form-control"
                          id="other_expenses_value"
                          v-money="money"
                          v-model.lazy="form.other_expenses_value"
                          :disabled="hasPaidBill"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="3">
                      <b-form-group id="field_discount">
                        <label for="discount" class="title">
                          Desconto
                        </label>
                        <b-form-input
                          autocomplete="off"
                          class="form-control"
                          id="discount"
                          v-model.lazy="form.discount"
                          v-money="money"
                          :disabled="hasPaidBill"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group id="field_total_value">
                        <label for="total_value" class="title">
                          Valor total dos produtos
                        </label>
                        <b-form-input
                          autocomplete="off"
                          class="form-control"
                          id="total_value"
                          :value="parseNumberToMoney(totalValue)"
                          v-money="money"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group id="field_invoice_total_value">
                        <label for="invoice_total_value" class="title">
                          Valor total da nota
                        </label>
                        <b-form-input
                          autocomplete="off"
                          class="form-control"
                          id="invoice_total_value"
                          v-money="money"
                          :value="parseNumberToMoney(invoiceTotalValue)"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        id="field_observations"
                      >
                        <label for="observations" class="title">
                          Observações
                        </label>
                        <b-form-input
                          id="observations"
                          trim
                          autocomplete="off"
                          placeholder="Descrever observações"
                          v-model="form.observations"
                          :disabled="hasPaidBill"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
        </validation-observer>
        <div v-if="!form.id && (!form.transactions || !form.transactions.length)" class="add-product-container">
          <hr />
          <div class="add-product">
            <b-button
              class="wh-button add-product-button"
              variant="outline-primary"
              size="lg"
              @click="$bvModal.show('warehouse-product-entry')"
            >
              Adicionar produto
            </b-button>
          </div>
          <hr />
        </div>

        <div v-else class="product-list">
          <ProductListTable
            :editable="!form.id"
            :useType="form.type"
            :transactions="form.transactions"
            :other-costs="otherCosts"
            @onAddProduct="$bvModal.show('warehouse-product-entry')"
            @onEdit="editProductList"
            @onDelete="deleteProduct"
          />
        </div>
        <b-alert show variant="warning" class="warning-alert" v-if="hasPaidBill">
          <span>
            <Warning class="warning-icon"></Warning>
            Não é possível editar a entrada, pois a conta a pagar vinculada já foi paga ou paga parcial.
          </span>
        </b-alert>
        <div class="md-button">
          <div class="bill-to-pay-option-wrapper" v-if="!billToPayId">
            <Check v-model="createBillToPay" :disabled="hasBill" />
            <span class="text">Gerar conta a pagar</span>
          </div>
          <div>

            <b-button
              size="lg"
              variant="outline"
              class="attach-button"
              @click="openNewDocumentModal"
            >
              <Clip class="clip" />
              Anexar arquivo
            </b-button>
            <b-button
              v-if="!hasUpdatedValues && hasBill"
              class="wh-button mr-2"
              variant="outline-primary"
              size="lg"
              :disabled="!form.date || (!form.transactions || !form.transactions.length) || hasPaidBill"
              :class="{'disabled': !form.date || (!form.transactions || !form.transactions.length) || hasPaidBill}"
              @click="updateWareHouseEntry(false)"
            >
              Atualizar entrada
            </b-button>
            <span id="finish_button">
            <b-button
              class="wh-button"
              variant="primary"
              size="lg"
              :disabled="!form.date || (!form.transactions || !form.transactions.length) || hasPaidBill"
              :class="{'disabled': !form.date || (!form.transactions || !form.transactions.length)}"
              @click="onSave"
            >
              {{ getFinishButtonName }}
            </b-button>
            </span>
            <b-tooltip v-if="!form.transactions || !form.transactions.length" target="finish_button" triggers="hover">
              Adicione pelo menos um produto ou mais para efetuar entrada
            </b-tooltip>
          </div>
        </div>
      </div>
    </b-modal>

    <ProductTransactionModal
      identifier="warehouse-product-entry"
      :useType="form.type"
      :transaction="transaction"
      @onSave="onModalAddProduct"
      @onUpdate="onModalUpdateProduct"
      @onHide="resetTransaction"
    />

    <ConfirmEntryModal
      identifier="confirm-edit-entry"
      title="Avançar"
      body="Atenção! Tem certeza de que deseja avançar e substituir esta conta a pagar?"
      description="Esta ação é irreversível e exclui todas as parcelas e a conta atual."
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      :confirmed="confirmedNext"
    />

    <ConfirmEntryModal
      identifier="confirm-close-entry-modal"
      title="Aviso"
      body="Atenção! Existem atualizações nesta entrada não concluídas."
      description="Deseja realmente sair sem salvar?"
      cancelButtonText="Voltar"
      confirmButtonText="Sair sem salvar"
      :confirmed="confirmedClose"
    />

    <ProviderModal
      identifier="provider-modal"
      :provider="null"
      @onHide="getProviders"
    />

  </div>
</template>
<script>
import { debounce } from 'lodash'
import { track } from '@/utils/mixPanel'
import { EventBus } from '@/utils/eventBus'
import { mapActions, mapGetters } from 'vuex'
import ConfirmEntryModal from '@/components/Warehouse/ConfirmEntryModal.vue'
import { VMoney } from 'v-money'
import { parseMoneyToNumber, parseNumberToMoney } from '@/utils/moneyHelper'

export default {
  name: 'WarehouseEntryModal',
  components: {
    ConfirmEntryModal,
    'Clip': () => import('@/assets/icons/clip.svg'),
    'Close': () => import('@/assets/icons/close.svg'),
    'ProductTransactionModal': () => import('@/components/Warehouse/ProductTransactionModal'),
    'ProductListTable': () => import('@/components/Warehouse/ProductListTable'),
    'Search': () => import('@/assets/icons/search.svg'),
    'Plus': () => import('@/assets/icons/plus.svg'),
    'ProviderModal': () => import('@/components/Warehouse/ProviderModal'),
    'Check': () => import('@/components/General/Check'),
    'ChevronDown': () => import('@/assets/icons/chevron-down.svg'),
    'Warning': () => import('@/assets/icons/warning-stroke.svg'),
    CheckboxSelect: () => import('@/components/CheckboxSelect.vue'),
  },
  props: {
    entry: Object,
    openBillToPayProductModal: Function,
    defaultTransactions: Array
  },
  created() {
    this.getProvidersDebounce = debounce(this.getProviders, 600)
    this.getCategories();
    this.getCostCenters();
  },
  data() {
    return {
      billToPayId: null,
      clinic: JSON.parse(localStorage.getItem('clinic')),
      configuration: {},
      verified: false,
      isLoading: false,
      createBillToPay: true,
      form: this.defaultForm(),
      providers: [],
      transaction: null,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false
      },
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      },
      categoriesPay: [],
      categories: [],
      selectedCostCenters: [],
			costCenterOptions: [],
    }
  },
  computed: {
    ...mapGetters('warehouse', ['getSelectedFile', 'getSelectedEntry']),
    otherCosts() {
      return parseMoneyToNumber(this.form.other_expenses_value)
        + parseMoneyToNumber(this.form.insurance_value)
        + parseMoneyToNumber(this.form.delivery_value)
        - parseMoneyToNumber(this.form.discount)
    },
    totalValue() {
      return this.form.transactions?.reduce((total, item) => total + (item.quantity * item.purchase_price), 0)
    },
    invoiceTotalValue() {
      return this.totalValue + this.otherCosts
    },
    hasBill() {
      return !!this.entry?.bill_product?.bill_id && !!this.entry?.bill_product?.bill
    },
    hasPaidBill() {
      if(!this.entry?.bill_product?.bill?.payments?.length) {
        return false
      }
      return this.entry.bill_product.bill.payments.some(payment => {
        return payment.installments?.some(installment => installment.status === 'paid_out')
      })
    },
    getFinishButtonName() {
      if(this.createBillToPay) {
        return 'Avançar'
      }
      return this.form.id ? 'Atualizar entrada' : 'Efetuar entrada'
    },
    hasUpdatedValues() {
      return this.form.id && this.entry?.invoice_total_value !== this.invoiceTotalValue
    },
  },
  methods: {
    parseNumberToMoney,
    ...mapActions('warehouse', ['updateSelectedEntry']),
    onShow() {
      this.getWarehouseConfigurations()
      this.form = this.defaultForm()
      if(this.entry) {
        this.form = {
          ...this.entry,
          delivery_value: parseNumberToMoney(this.entry.delivery_value),
          insurance_value: parseNumberToMoney(this.entry.insurance_value),
          other_expenses_value: parseNumberToMoney(this.entry.other_expenses_value),
          discount: parseNumberToMoney(this.entry.discount),
          total_value: parseNumberToMoney(this.entry.total_value),
          invoice_total_value: parseNumberToMoney(this.entry.invoice_total_value),
          date: this.moment(this.entry.date).toDate(),
          category: this.entry?.bill_product?.bill?.category,
        }
        
        this.billToPayId = this.entry?.bill_product?.bill?.id;

        if (this.entry?.bill_product?.bill?.cost_center) {
          this.selectedCostCenters = this.entry?.bill_product?.bill?.cost_center.map(center => {
            return {
              value: center.id,
              label: center.name,
            }
          });
          this.changeSelectedCostCenters(this.selectedCostCenters)
        }
      }
    },
    defaultForm() {
      return {
        date: new Date(),
        provider: null,
        invoice_number: null,
        serial_number: null,
        access_key: null,
        delivery_value: parseNumberToMoney(0),
        insurance_value: parseNumberToMoney(0),
        other_expenses_value: parseNumberToMoney(0),
        discount: parseNumberToMoney(0),
        total_value: parseNumberToMoney(0),
        invoice_total_value: parseNumberToMoney(0),
        observations: null,
        transactions: [],
        categories: [],
        selectedCostCenters: [],
        costCenterOptions: [],
        createBillToPay: true,
      }
    },
    onClickCloseModal() {
      if((this.form.transactions.length || this.form.provider !== null) && !this.hasPaidBill){
        this.$bvModal.show('confirm-close-entry-modal')
      }else{
        this.confirmedClose(true)
      }
    },
    confirmedClose(confirmed) {
      if (confirmed) {
        this.clearForm()
        this.$emit('onHide')
        this.$bvModal.hide('warehouse-entry-modal')
      }
      this.$bvModal.hide('confirm-close-entry-modal')
    },
    formattedForm() {
      return {
        ...this.form,
        delivery_value: parseMoneyToNumber(this.form.delivery_value),
        insurance_value: parseMoneyToNumber(this.form.insurance_value),
        other_expenses_value: parseMoneyToNumber(this.form.other_expenses_value),
        discount: parseMoneyToNumber(this.form.discount),
        total_value: parseMoneyToNumber(this.totalValue),
        invoice_total_value: parseMoneyToNumber(this.invoiceTotalValue),
      }
    },
    confirmedNext(confirmed) {
      if (confirmed) {
        this.updateSelectedEntry({
          ...this.formattedForm(),
          files: this.getSelectedFile ? [this.getSelectedFile] : [],
          total_value: this.totalValue,
          invoice_total_value: this.invoiceTotalValue
        })
        this.showBillToPayProductModal()
      }
      this.$bvModal.hide('confirm-edit-entry')
    },
    async onSave() {
      if (this.createBillToPay) {
        if (this.form.id) {
          this.$bvModal.show('confirm-edit-entry')
        } else {
          await this.updateSelectedEntry({
            ...this.formattedForm(),
            files: this.getSelectedFile ? [this.getSelectedFile] : [],
            total_value: this.totalValue,
            invoice_total_value: this.invoiceTotalValue,
          })
          this.showBillToPayProductModal()
        }
      } else if (this.form.id) {
        this.updateWareHouseEntry()
      } else {
        this.createWareHouseEntry()
      }
    },
    openNewDocumentModal() {
      this.$bvModal.show('generic-attach-file-modal')
    },
    onModalAddProduct(transaction) {
      if (!this.form.transactions) {
        this.form.transactions = []
      }
      const transactions = this.form.transactions
        .filter(el => {
          return el.product.id !== transaction.product.id || el.batch !== transaction.batch
        })
        .concat([transaction])
      this.form = { ...this.formattedForm(), transactions }
    },
    onModalUpdateProduct(transaction) {
      this.form.transactions = this.form.transactions.map(t => {
        if (t.id === transaction.id) return transaction
        return t
      })
    },
    resetTransaction() {
      this.transaction = null
    },
    editProductList(transaction) {
      this.transaction = Object.assign({}, transaction)
      this.$bvModal.show('warehouse-product-entry')
    },
    async deleteProduct(transaction) {
      try {
        transaction.id && await this.deleteWarehouseTransaction(transaction)
        const index = this.form.transactions.indexOf(transaction)
        const transactions = [...this.form.transactions]
        transactions.splice(index, 1)
        this.form.transactions = transactions
        this.$toast.success('Produto removido com sucesso!')
        EventBus.$emit('reloadProductEntries')
        EventBus.$emit('reloadProducts')
      } catch (e) {
        return this.$toast.error('Não foi possível remover o produto.')
      }
    },
    getProviders(query) {
      this.isLoading = true
      this.api.getProviders(this.clinic.id, 1, query)
        .then(res => {
          this.providers = res.data.data
        })
        .catch(err => {
          this.$toast.error(err.message)
        }).finally(() => {
        this.isLoading = false
      })
    },
    async uploadFile(warehouseEntryId) {
      if (this.getSelectedFile?.file && this.getSelectedFile?.filename) {
        const dataForm = new FormData()
        dataForm.append(`file`, this.getSelectedFile.file)
        dataForm.append(`filename`, this.getSelectedFile.filename)
        dataForm.append('clinic_id', this.clinic.id)
        dataForm.append('warehouse_entry_id', warehouseEntryId)

        const isLoading = this.$loading.show()
        this.api
          .createWarehouseEntryFile(dataForm)
          .then(response => {
            this.$toast.success('Arquivo enviado com sucesso')
            this.error = false
            this.$bvModal.hide('new-document-modal')
          })
          .catch(error => {
            this.$toast.error(error.message)
            this.error = true
          })
          .finally(() => {
            isLoading.hide()
          })
      }
    },
    createWareHouseEntry() {
      if (!this.isValidForm()) return

      const props = {
        ...this.formattedForm(),
        clinic_id: this.clinic.id,
        provider_id: this.form.provider ? this.form.provider.id : null
      }
      const isLoading = this.$loading.show()
      this.api.createWarehouseEntry(props)
        .then(async (res) => {
          await this.uploadFile(res.data.id)
          this.updateSelectedEntry(res.data)
          this.showBillToPayProductModal(res.data)
          this.$toast.success('Entrada realizada com sucesso!')
          this.clearForm()
          this.$emit('reloadBatches')
          this.$emit('onHide')
          this.$bvModal.hide('warehouse-entry-modal')
          track('entrada_de_produtos', {
            clinic: this.clinic.name
          })
        })
        .catch(err => {
          this.$toast.error(err.message)
        })
        .finally(() => {
          isLoading.hide()
          EventBus.$emit('reloadProductEntries')
          EventBus.$emit('reloadProducts')
        })
    },
    updateWareHouseEntry(showBillModal = true) {
      if (!this.isValidForm()) return
      const isLoading = this.$loading.show()

      const props = {
        clinic_id: this.clinic.id,
        provider_id: this.form.provider?.id || null,
        ...this.formattedForm()
      }
      this.api.updateWarehouseEntry(this.form.id, props)
        .then(async (res) => {
          await this.uploadFile(this.form.id)
          this.updateSelectedEntry(res.data)
          showBillModal && this.showBillToPayProductModal(res.data)
          this.clearForm()
          EventBus.$emit('reloadBatches')
          this.$toast.success('Entrada atualizada com sucesso!')
          this.$bvModal.hide('warehouse-entry-modal')
          this.$emit('onHide')
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          isLoading.hide()
          EventBus.$emit('reloadProductEntries')
          EventBus.$emit('reloadProducts')
        })
    },
    deleteWarehouseTransaction(transaction) {
      return new Promise((resolve, reject) => {
        const isLoading = this.$loading.show()
        this.api.deleteWarehouseTransaction(transaction.id)
          .then(resolve)
          .catch(reject)
          .finally(() => isLoading.hide())
      })
    },
    getWarehouseConfigurations() {
      this.api.getWarehouseConfigurations(this.clinic.id)
        .then(res => {
          this.configuration = res.data
        })
        .catch(err => this.$toast.error(err.message))
    },
    clearForm() {
      this.form = this.defaultForm();
      this.billToPayId = null;
      this.selectedCostCenters = [];
      this.verified = false;
      this.createBillToPay = true;
    },
    isValidForm() {
      this.verified = true
      let isValid = true

      if (!this.form.date) {
        isValid = false
        this.$toast.warning('Informe a data de entrada.')
      }

      if (!this.form.category && this.createBillToPay) {
        isValid = false
        this.$toast.warning('Informe uma categoria.')
      }

      if (this.configuration.provider_required && !this.form.provider) {
        isValid = false
        this.$toast.warning('Informe o fornecedor.')
      }

      if (!this.form.transactions || !this.form.transactions.length) {
        isValid = false
        this.$toast.warning('Adicione no mínimo um produto.')
      }

      return isValid
    },
    showBillToPayProductModal() {
      if (!this.createBillToPay) return
      const billToPay = {
        service_date: this.form.date,
        transactions: this.form.transactions,
        entry: this.entry,
        category: this.form.category,
        costCenters: this.form.costCenters,
      }
      this.openBillToPayProductModal(billToPay)
    },
    async getCategories() {
			try {
				let newValue = null
				await this.api.getCategories(this.clinic.id, 'Saída').then(res => {
						this.categoriesPay = res.data.map(el => ({
						...el,
						label: `${el.name}`,
						value: `${el.id}`
						}))
						this.categories = res.data.filter(el => !['Procedimento', 'Produto'].includes(el.name));
						newValue = res.data.find(
						el => el.default_category_accounts_payable
						)
					})
				this.form.category = this.form.category ? this.form.category : newValue
				return this.categories
			} catch (error) {
				this.$toast.error(error.message)
				throw new Error(400)
			}
		},
    async getCostCenters() {
			await this.api.getCostCenters(this.clinic.id)
				.then(res => {
					this.costCenterOptions = []
					res.data.map((center) => {
						if (center.active) {
							this.costCenterOptions.push({
								value: center.id,
								label: center.name,
							})
							if (!this.billToPayId && center.is_default) {
								this.selectedCostCenters = [{
									value: center.id,
									label: center.name,
								}]
								this.changeSelectedCostCenters(this.selectedCostCenters)
							}
						}
					})
				})
				.catch(err => {
					this.$toast.error(err.message)
				})
		},
		changeSelectedCostCenters(value) {
      this.form.costCenters = value.map(costCenter => (costCenter.value));
		},
  },
  watch: {
    defaultTransactions: {
      handler: function(newValue) {
        if (newValue) {
          this.$set(this.form, 'transactions', newValue)
        }
      },
      deep: true
    }
  },
  directives: { money: VMoney }
}
</script>
<style lang="scss">
#warehouse-entry-modal {
  .warning-alert {
    background-color: #FFEBCC;
    border-color: #FFEBCC;
    border-radius: 8px;
    padding: 12px 24px 12px 24px;
    font-size: 14px;
    height: 48px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #525c7a;
    margin: 0 16px;
    .warning-icon {
      width: 24px;
      height: 24px;
      margin-top: 4px;
    }
  }
  .modal-content {
    max-width: 750px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);

    .modal-body {
      padding: 0 !important;

      .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        border-bottom: 1px solid var(--neutral-200);

        .close-svg {
          height: 24px;
          width: 24px;
          fill: var(--neutral-500);
        }

        .modal-title {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--type-active);
        }
      }

      .body {
        .row {
          padding: 16px;
          margin: 0 !important;

          .icon {
            width: 25px;
          }

          .invalid-feedback {
            display: block;
            width: 100%;
            margin-top: 0.25rem;
            font-size: 80%;
            color: #dc3545;
          }
        }

        .accordion-form {
          margin-bottom: 0;
          padding: 0;
        }

        .md-content {
          padding: 24px 24px 0;

          .no-padding {
            padding: 0 !important;
          }

          .padding_24_0_0_0 {
            padding: 24px 0 0 0 !important;
          }

          .padding_0_8_0_0 {
            padding: 0 8px 0 0 !important;
          }

          .padding_0_0_24_0 {
            padding: 0 0 24px 0 !important;
          }

          .multiselect {
            margin: 0 !important;
            padding: 0 !important;
          }

          .title {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            overflow: clip;
            color: var(--dark-blue);

            .optional {
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 130%;
              color: var(--type-placeholder);
            }
          }
        }

        .multiselect {
          .custom-item {
            color: var(--blue-500);
            display: flex;
            align-items: center;
            font-weight: 600;

            .icon {
              width: 24px;
              height: 24px;
              fill: var(--blue-500);
              margin-right: 12px;
            }
          }
        }

        .product-list {
          padding: 0 20px;
        }
      }

      .md-button {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        margin: 24px;

        .bill-to-pay-option-wrapper {
          display: inline-flex;
          align-items: center;

          .text {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: var(--type-active);
          }
        }

        button {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          padding: 8px 16px;
        }
      }
    }

    .add-product-container {
      padding: 0 24px;

      hr {
        margin: 0 !important;
      }

      .add-product {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          padding: 12px 47px !important;
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
        }

        .add-product-button {
          margin: 24px 0;
        }
      }
    }
  }

  .form-card {
    border-radius: 8px;
  }

  .accordion-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--blue-500);
    margin-bottom: 4px;

    .optional {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: var(--type-placeholder);
    }

  }

  .divider {
    border-bottom: 1px solid var(--neutral-300);
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .chevron {
    width: 24px;
    height: 24px;
    stroke: var(--neutral-600);
    transition: all 0.5s;
    cursor: pointer;

    &.collapsed {
      animation: rotateUp 0.5s;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }

    &.not-collapsed {
      animation: rotateDown 0.5s;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      transform: rotate(180deg);
    }

    &.chevron:focus {
      outline: none !important;
    }
  }
}

.attach-button {
  color: var(--blue-500);

  .clip {
    margin-top: -5px;
    stroke: var(--blue-500);
  }
}

#field_observations {
  margin: 0 !important;
}
</style>
