<template>
  <b-modal
    :id="identifier"
    centered
    hide-header
    hide-footer
    class="confirm-modal"
    @hidden="confirmed(false)"
  >
    <div class="header">
      <div class="header-content">
        <p>{{ title }}</p>
        <v-close class="close-svg" @click="confirmed(false)" />
      </div>
    </div>
    <div class="body">
      <p>
        {{ body }}
      </p>
      <span class="description">
        {{ description }}
      </span>
    </div>

    <div class="actions">
      <b-button
        class="cancel-button"
        variant="outline-danger"
        @click="confirmed(false)"
      >
        {{ cancelButtonText }}
      </b-button>
      <b-button
        variant="primary"
        @click="confirmed(true)"
      >
        {{ confirmButtonText }}
      </b-button>
    </div>
  </b-modal>
</template>
<script>
import Close from '@/assets/icons/close.svg'

export default {
  components: {
    'v-close': Close
  },
  props: {
    identifier: String,
    title: String,
    body: String,
    confirmButtonText: String,
    cancelButtonText: String,
    description: String,
    confirmed: Function
  }
}
</script>
<style lang="scss" scoped>
.header-content {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: var(--type-active);
}
.modal-content {
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 450px;
  .modal-body {
    padding: 0;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--type-active);

    .header {
      border-bottom: 1px solid var(--neutral-200);

      .header-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 24px;

        .close-svg {
          height: 24px;
          width: 24px;
          fill: var(--neutral-500);
        }
      }
    }

    .body {
      padding: 24px;
      color: var(--type-active);
      font-size: 18px;
      font-weight: 400;

      .description {
        color: var(--type-placeholder);
      }
    }

    .actions {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: end;
      padding: 24px;

      button {
        margin-left: 10px !important;
      }

      .cancel-button {
        font-weight: bold !important;
        border-color: transparent !important;
      }
    }
  }
}
</style>
